.messageContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1px;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}

.messageBox {
  background: #f3f3f3;
  border-radius: 20px 20px 20px 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
  margin-left: 5px;
}

.messageBoxPaid {
  background: #f3f3f3;
  border-radius: 0px 20px 20px 20px;
  padding: 5px 20px;
  color: #000;
  display: inline-block;
  max-width: 80%;
  margin-left: 5px;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
  margin-bottom: 0px;
  border-radius: 20px 20px 20px 20px;
}

.messageInfo {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.messageImage {
  width: 50%;
  height: 100%;
  object-fit: cover;
  border-radius: 5%;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.backgroundYellow {
  background-color: #e8cc54;
  color: black;
}

.backgroundLight {
  background-color: #efefef;
  color: black;
}

.messageFile {
  width: 100% !important;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  /* background-color: aliceblue; */
  margin-top: 5px;
}

.audioFile {
  width: 100%;
  margin: 5px 0px;
}

.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 5px auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation:
    fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.renderDiscussionMessage {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
