/* @font-face {
    font-family: 'KohinoorSemiBold';
    src: local('KohinoorSemiBold'), url(./assets/fonts/KohinoorDevanagari-Semibold.ttf) format('ttf');
}

@font-face {
    font-family: 'KohinoorRegular';
    src: local('KohinoorRegular'), url(./assets/fonts/KohinoorDevanagari-Regular.ttf) format('ttf');
}

@font-face {
    font-family: 'InterRegular';
    src: local('InterRegular'), url(./assets/fonts/Inter-Regular.ttf) format('ttf');
} */

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";

.e-multi-select-wrapper {
  border: solid lightgray 1px !important;
  border-radius: 20px;
  padding-left: 5px;
}

.Toastify__toast--success,
.Toastify__toast--error {
  text-align: center;
  font-family: KohinoorRegular;
  font-size: 15px;
}
.Toastify__toast--success {
  background-color: #e8cc54;
}
.Toastify__toast--error {
  background-color: #ef271b;
}

/* Stripe.jsx */
/* #payment-message {
	color: rgb(105, 115, 134);
	font-size: 16px;
	line-height: 20px;
	padding-top: 12px;
	text-align: center;
}

#payment-element {
	margin-bottom: 24px;
} */

/* form {
	width: 30vw;
	min-width: 500px;
	align-self: center;
	box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
		0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
	border-radius: 7px;
	padding: 40px;
} */

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

@media only screen and (max-width: 600px) {
  form {
    width: 100%;
    min-width: initial;
  }
}

@keyframes shadow-pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }
}

.logo-loading {
  animation: shadow-pulse 800ms infinite;
}

.sidenav.modal-dialog {
  margin: 0 !important;
}

.seen-count {
  background-color: rgba(35, 35, 35, 0.435);
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  font-weight: 500;
  color: rgb(229, 229, 229);
  z-index: 1;
  bottom: 10px;
  left: 25px;
  font-size: 15px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 2px;
}

@media print {
  html,
  body {
    display: none; /* hide whole page */
  }
}

.makeblur {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3); /* Slight white overlay */
  backdrop-filter: blur(10px);
}

.stage {
  display: flex;
  margin-left: -20px;
}

.heart {
  width: 40px;
  height: 40px;
  /* background: url("https://cssanimation.rocks/images/posts/steps/heart.png") no-repeat; */
  background: url("./assets/bise.png") no-repeat;
  background-position: center;
  border-radius: 100%;
  cursor: pointer;
  transition-duration: background 400ms ease-in;
  /* margin-right: 30px; */
  /* margin-top: 10px; */
  /* transition: background-position 1s steps(28); */
  /* transition-duration: 0s; */
}
.is-active-heart {
  transition-duration: background 400ms ease-in;
  /*background-position: -2795px 0;*/
  background-color: rgb(245, 193, 5);
}

@media screen and (max-width: 768px) {
  .headerMenu span {
    font-size: 12px !important;
  }
  .mybeedoo-logo {
    height: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .headerMenu span {
    font-size: 11px !important;
  }
}

.mbr_check_rounded {
  background-color: #1eb51e;
  border-radius: 100%;
  width: 250px;
  height: 250px;
  animation: check-animation 1000ms ease-in-out;
}

.mbr_refused_rounded {
  width: 250px;
  height: 250px;
  animation: check-animation 1000ms ease-in-out;
}

@keyframes check-animation {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
