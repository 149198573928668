@-webkit-keyframes pulse {
   0% {
     -webkit-transform: scale(1);
     transform: scale(1);
   }
   50% {
     -webkit-transform: scale(1.1);
     transform: scale(1.1);
   }
   100% {
     -webkit-transform: scale(1);
     transform: scale(1);
   }
 }
 
 @keyframes pulse {
   0% {
     -webkit-transform: scale(1);
     transform: scale(1);
   }
   50% {
     -webkit-transform: scale(1.1);
     transform: scale(1.1);
   }
   100% {
     -webkit-transform: scale(1);
     transform: scale(1);
   }
 }
 
 .pulse {
   -webkit-animation-name: pulse;
   animation-name: pulse;
   -webkit-animation-duration: 1s;
   animation-duration: 1s;
   -webkit-animation-timing-function: linear;
   animation-timing-function: linear;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
   
 }

 .bee{
  width: 15%;
  height: 15%;
  margin-top: 40vh;
  
 }