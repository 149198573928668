.btn-rounded {
  border-radius: 300rem !important;
}

.btn-yellow {
  background-color: #f7cc2a !important;
}

.btn-black {
  background-color: black !important;
}
.btn-gray {
  background-color: #e8e8ea !important;
}
.btn-dark {
  background-color: black !important;
  color: lightgray !important;
}

.customBtnYellow {
  background-color: #e8cc54 !important;
}
.customYellow {
  background-color: #f7cc2a !important;
}
.customRed {
  background-color: #dc010a !important;
}
.pointer-cursor {
  cursor: pointer;
}
.underline-txt:hover {
  text-decoration: underline;
}
.rounded_50 {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover;
}
.rounded_40 {
  width: 35px !important;
  height: 35px !important;
  object-fit: cover;
}

.rounded_44 {
  width: 44px !important;
  height: 44px !important;
  object-fit: cover;
}

.rounded_35 {
  width: 35px !important;
  height: 35px !important;
  object-fit: cover;
}

.rounded_30 {
  width: 30px !important;
  height: 30px !important;
  object-fit: cover;
}
.rounded_26 {
  width: 26px !important;
  height: 26px !important;
  object-fit: cover;
}
.rounded_20 {
  width: 20px !important;
  height: 20px !important;
  object-fit: cover;
}

@font-face {
  font-family: "KohinoorSemiBold";
  src:
    local("KohinoorSemiBold"),
    url(./assets/fonts/KohinoorDevanagari-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "KohinoorMedium";
  src:
    local("KohinoorMedium"),
    url(./assets/fonts/KohinoorDevanagari-Medium.otf) format("truetype");
}

@font-face {
  font-family: "KohinoorRegular";
  src:
    local("KohinoorRegular"),
    url(./assets/fonts/KohinoorDevanagari-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "InterRegular";
  src:
    local("InterRegular"),
    url(./assets/fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "InterMedium";
  src:
    local("InterMedium"),
    url(./assets/fonts/Inter-Medium.otf) format("truetype");
}

@font-face {
  font-family: "InterSemiBold";
  src:
    local("InterSemiBold"),
    url(./assets/fonts/InterSemiBold600.otf) format("truetype");
}

/*FONT CLASS */
.k-medium-20 {
  font-family: KohinoorMedium;
  font-size: 20px !important;
}
.k-medium-15 {
  font-family: KohinoorMedium;
  font-size: 12px !important;
}

.k-medium-13 {
  font-family: KohinoorMedium;
  font-size: 13px !important;
}
.k-regular-20 {
  font-family: KohinoorRegular;
  font-size: 20px !important;
}
.k-regular-15 {
  font-family: KohinoorRegular;
  font-size: 15px !important;
}

.k-regular-13 {
  font-family: KohinoorRegular;
  font-size: 13px !important;
}

.k-regular-12 {
  font-family: KohinoorRegular;
  font-size: 12px !important;
}

.k-regular-11 {
  font-family: KohinoorRegular;
  font-size: 11px !important;
}

.k-semibold-20 {
  font-family: KohinoorSemiBold;
  font-size: 20px !important;
}
.k-semibold-15 {
  font-family: KohinoorSemiBold;
  font-size: 15px !important;
}

.k-semibold-13 {
  font-family: KohinoorSemiBold;
  font-size: 13px !important;
}

.k-semibold-12 {
  font-family: KohinoorSemiBold;
  font-size: 12px !important;
}

.k-semibold-11 {
  font-family: KohinoorSemiBold;
  font-size: 11px !important;
}

.inter-regular-15 {
  font-family: InterRegular;
  font-size: 15px !important;
}

.inter-regular-13 {
  font-family: InterRegular;
  font-size: 12px !important;
}

.inter-medium-15 {
  font-family: InterMedium;
  font-size: 13px !important;
}

.inter-medium-13 {
  font-family: InterMedium;
  font-size: 13px !important;
}

.inter-semibold-15 {
  font-family: InterSemiBold;
  font-size: 15px !important;
}

.inter-semibold-13 {
  font-family: InterSemiBold;
  font-size: 13px !important;
}

.gallery input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border-radius: 3px;
  background-color: #f7cc2a;
}
.gallery input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid black 1px;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

/* MENU SLIDER */

.menuIcons {
  z-index: 2001;
  position: absolute;
  top: 0;
  left: 0;
}

.RightMenuIcons {
  z-index: 100;
  position: absolute;
  bottom: 14%;
  right: 1%;
}

.RightMenuIconsPanier {
  z-index: 100;
  position: absolute;
  bottom: 42px;
  right: 1%;
}
.LeftMenuIconsPanier {
  z-index: 2001;
  position: absolute;
  top: 88px;
  left: 1%;
  margin-top: 20px;
}

.footer a,
.footer p {
  text-decoration: none;
  color: #606060;
  font-family: InterRegular;
  font-size: 12px;
}

.sticky-footer {
  position: sticky;
  top: 100px;

  /* position: fixed;
  bottom: 0; */
}

.bg-yellow {
  background-color: #f7cc2a !important;
}

.fb-icon {
  border: solid red;
  position: relative;
  left: 0;
}

.custom-fb-login {
  border: 0;
  background: none;
  padding: 0;
  width: 100%;
}

#mainContainer {
  display: flex; /* establish flex container */
  flex-direction: column; /* make main-axis vertical */
  justify-content: center; /* align items vertically, in this case */
  align-items: center; /* align items horizontally, in this case */
  height: 50vh;
}

.height100 {
  height: 95%;
  overflow: scroll;
  scrollbar-width: none;
}

/* mobile screen */
@media screen and (max-width: 575px) {
  ::-webkit-scrollbar {
    display: none;
  }

  .height100 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 60px;
    scrollbar-width: none;
  }

  .scrollable100{
    height: 100%;
    overflow: scroll;
    scrollbar-width: none;
  }

  .k-regular-15 {
    font-size: 13px !important;
  }

  .k-semibold-15 {
    font-size: 13px !important;
  }

  .inter-regular-15 {
    font-size: 13px !important;
  }

  .inter-medium-15 {
    font-size: 13px !important;
  }

  .inter-semibold-15 {
    font-size: 13px !important;
  }

  .goback {
    position: absolute;
    left: 15px;
  }
}

.container-xl {
  max-width: 1998px !important;
}

/* img {pointer-events: none !important} */

main-index-column {
  display: flex;
  flex-direction: column;
}

section-fixed-column {
  flex: 0 0 auto;
}

section-dynamic-column {
  flex: 1 1 auto;
}
/* body {
  scrollbar-width: 12px;
  scrollbar-color: #888 #f1f1f1;
} */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

#root {
  height: 100vh;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
