.mb_details_message{
  overflow: hidden;
}

.inputMessageIcon {
  cursor: pointer;
}

.new-user-wapper > div {
  width: 100%;
}

.loadingSpinner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0px;
  border-style: none;
}

.loadingSpinner::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #333;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* @media screen and (min-width: 575px) {
  .discussions-list,
  .discussions-details {
    position: relative;
    border: #ccc solid 1px;
    padding: 0;
    margin: 0;
    height: 100%;
  }

  .discussions-details {
    border-radius: 0 5px 5px 0;
  }

  .discussions-list {
    border-radius: 5px 0px 0px 5px;
  }

  .Badge-plus {
    background-color: #e8cc54;
    color: #fff;
    font-size: 1.2em;
  }
  .user-details {
    padding: 0;
  }

  .discussions-details .icon-i {
    background-color: #e6ebf0;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }

  .discussions-details .inputMessage {
    position: absolute;
    bottom: 0px;
    left: 5px;
    right: 5px;
  }
  .inputSearch {
    padding: 0px 10px;
  }
  .inputMessageIcon {
    margin: 0px 5px;
  }
  .selected {
    background-color: #efefef;
  }
} */

/* .inputMessageText {bottom: -50px; position: absolute; width: -webkit-fill-available; background-color: #fff;} */

/* .discussionList {height: calc(100vh - 225px)} */

.discussions-details {
  height: 95%;
}

.discussionList2 {
  background-color: red;
}

.discussionList {
  height: 88% !important;
  margin-bottom: 2px;
}

.inputMessageText {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollable {
  height: 100% !important;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  scrollbar-width: none;
}
/* 
.discussionList .scrollable{
    height: 100% !important;
}
.scrollable .infinite-scroll-component__outerdiv{
    height: 100% !important;
} */

/* .discussions-list .infinite-scroll-component__outerdiv {
  height: 100%;
} */

.discussion-header {
  flex: 0 0 auto;
}

.discussionList {
  flex: 1 1 auto;
  overflow-y: auto;
}

.inputMessageText {
  flex: 0 0 auto;
}
