.custom-webcam {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.379);
  width: 100%;
  height: 100%;
  z-index: 4;
}

.custom-webcam .webcam-content {
  width: 80%;
  height: 80%;
  background-color: black;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

.custom-webcam .webcam-content .webcam {
  width: 100%;
  height: 70%;
}

.custom-webcam .webcam-content .webcam-list {
  display: flex;
  overflow: scroll;
  scrollbar-width: none;
  padding: 5px;
  height: 15%;
  width: 100%;
  background-color: #80808037;
}

.custom-webcam .webcam-content .webcam-list .webcam-list-content {
  width: auto;
  display: flex;
}

.custom-webcam .webcam-content .webcam-list .webcam-item {
  height: 100%;
  width: 100px;
  display: flex;
  margin-right: 5px;
}

.custom-webcam .webcam-content .webcam-list .webcam-item .media {
  height: 100%;
  width: 80%;
}

.custom-webcam .webcam-content .webcam-list .webcam-item .delete-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
  margin-left: 2px;
  background: #808080;
  border-radius: 5px;
  cursor: pointer;
}

.custom-webcam .webcam-content .webcam-options {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: 15%;
}

.custom-webcam .webcam-content .webcam-options .capture {
  background-color: white;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  outline: white 5px solid;
  border: 5px rgb(46, 46, 46) solid;
}

.click {
  transform: scale(1);
}

.click:active {
  transform: scale(0.8);
  transition: transform 10ms ease-in;
}
